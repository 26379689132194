import React, { Component } from 'react'
import Section from '../../section'
import Logo from '../../../assets/logo-intro.svg'
import './index.css'

export default class IntroExperience extends Component {
  render () {
    return <Section id={this.props.id} forceUpdate theme='blue' useDecorativeLines mobile={this.props.mobile}>
      <div className='exp-intro tc pt6 pb5 pt5-l pb5-l'>
        <img src={Logo} className='mt5 mt0-l db center' style={{ width: '45%' }} />
        <p className='info fos w-80 w-100-l ph4-l mt4 mt5-ns dib lh-copy f6 f5-l'>You've completed the Road Smart Interactive experience, but the real journey towards zero lives lost has only just begun.
  Here are some reminders of what you did during your visit and what you can do in the future.</p>
        <p className='cta mt4 mt5-l f6 f5-l'>SCROLL DOWN</p>
      </div>
    </Section>
  }
}
