import React, { Component, Fragment } from 'react'
import store from '../../../store'
import { selectContent } from '../../../selectors'
import Section from '../../section'
import Tracker from '../../gtm/event-tracker'
import Pledge from './pledge'
import './index.css'

const br = '<br class="dn db-m db-l" />'
const brMobile = '<br class="db dn-ns" />'
const TITLE_RSI_MYSELF = `THE TWO MOST ${br}IMPORTANT ACTIONS ${br + brMobile}I CAN TAKE`
const TITLE_RSI_SCHOOL = `THE TWO MOST ${br}IMPORTANT ACTIONS ${br + brMobile}MY SCHOOL CAN TAKE`

export default class YourPartExperience extends Component {
  constructor (props) {
    super(props)
    this.state = {
      cta: { label: 'READ MORE', url: 'https://www.tac.vic.gov.au/road-safety/road-users' },
      pledges: this.parsePledges(),
      graphs: this.parseGraphs()
    }
  }

  parsePledges () {
    const pledges = []
    const c = this.props.content
    if (!c || !c.pledges) return pledges

    const {
      'rsi-myself': rsiMyself = [],
      'rsi-school': rsiSchool = []
    } = c.pledges

    const linksMap = new Map()
    const links = selectContent(store.getState()).links

    // create a map with all links for yourparttablet app
    links.forEach((item, i) => {
      if (item.category === 'yourparttablet') {
        linksMap.set(item.type, Object.assign({}, item))
      }
    })

    const injectLinks = (list = []) => {
      return list.map(item => {
        if (typeof item === 'object' && item.linkId) {
          var { url = '' } = linksMap.get(item.linkId)

          if (url) {
            // inject url based on linkId of the current pledge
            return Object.assign({ url }, item)
          }
        }
        return item
      })
    }

    if (rsiMyself.length > 0) {
      pledges.push(this.createPledge(TITLE_RSI_MYSELF, injectLinks(rsiMyself)))
    }

    if (rsiSchool.length > 0) {
      pledges.push(this.createPledge(TITLE_RSI_SCHOOL, injectLinks(rsiSchool)))
    }

    return pledges
  }

  createPledge (title, list) {
    return <Pledge
      key={`pl-${title.toLowerCase()}`}
      title={title}
      list={list}
    />
  }

  parseGraphs () {
    const c = this.props.content
    if (!(c && c.graphs)) return null
    const rsiMyselfPath = c.graphs['rsi-myself']
    const rsiSchoolPath = c.graphs['rsi-school']
    const graphs = []
    if (rsiMyselfPath) graphs.push(this.createGraph(rsiMyselfPath, 'MYSELF'))
    if (rsiSchoolPath) graphs.push(this.createGraph(rsiSchoolPath, 'MY SCHOOL'))
    return graphs
  }

  createGraph (graphPath, graphTitle) {
    const hrefTitle = `Download ${graphTitle} Results Graph`
    const url = new URL(graphPath, window.location.origin).href
    return (
      <p key={`gr-${graphTitle.toLowerCase()}`} className='graph-image mt4 w-90 w-50-m w-40-l center'>
        <a target='_blank' title={hrefTitle} href={url}>
          <img src={url} alt={graphTitle} className='w-100 h-auto' />
        </a>
      </p>
    )
  }

  shouldComponentUpdate (nextProps, nextState) {
    return false
  }

  render () {
    // check how many categories the user has selected.
    const totalCategories = this.state.pledges.length

    return <Section id={this.props.id} className='your-part' theme='blue' useDecorativeLines='right'>
      <div className='tl tc-ns pv5 ph4 ph2-m ph0-ns'>
        <h1 className='heading1 lh-solid'>DO YOUR PART</h1>
        <p className='info center fos f6 f5-ns fw6-ns lh-copy mt3 mt4-ns ph0 ph4-ns'>By doing your part, we're one step closer to being the first generation with zero deaths on Victorian roads.</p>
        <Tracker value='do-your-part-cta-click'>
          <a href={this.state.cta.url} target='_blank' className='tbutton clear white flex mt4 mt5-ns'>{this.state.cta.label}</a>
        </Tracker>
        { totalCategories > 0
          ? <Fragment>
            <h3 className='dn db-ns fos heading4 mt6 mb4 mh4'>DOING YOUR PART</h3>
            <h3 className='dn-ns heading2 mt5 pv3'>DOING<br />YOUR PART</h3>
            {this.state.pledges}
            {this.state.graphs && <Fragment>
              <h3 className='dn db-ns fos heading4 mt5 mb4 mh4'>YOUR SESSION RESULTS</h3>
              {this.state.graphs}
            </Fragment> }
            <h2 className='heading2 mt4 mt5-ns mt6-ns mh4-ns'>THANK YOU</h2>
            <p className='mh4-ns mt3 mt4-ns pt1 pb3 pv0-ns lh-copy fos f6 f5-ns fw6-ns'>
                Road safety is everyone's responsibility.
              <br />
                Together we can achieve zero lives lost on our roads.
            </p>
          </Fragment>
          : null }
      </div>
    </Section>
  }
}
