import React from 'react'
import './index.css'
import { LinkLightbox } from '../../lightbox'
import Tracker from '../../gtm/event-tracker'

function youtubePlayer (url) {
  return <iframe width='560' height='315' src={url}
    frameBorder='0' allow='autoplay; encrypted-media' allowFullScreen
    className='image pa0' />
}

function nativeVideoPlayer (url) {
  return <video width='560' height='315' controls='true' src={url}
    className='image pa0' autoPlay='true' />
}

export default function ImageInfoPanel (props) {
  const details = props.children || null
  const side = props.imageSide || 'left'
  // image on the right side should use flex-row-reverse
  const direction = props.imageSide === 'right' ? '-reverse' : ''
  const theme = props.theme || 'white'
  const buttonCls = theme === 'blue' ? 'clear white' : ''
  const video = props.videoUrl || null
  const icon = props.icon || null
  const hasLightbox = !props.noLightbox
  let videoEl

  const openMediaCb = () =>
    props.openMedia(videoEl, { width: 560, height: 315 }, true)

  const ctaCls = `tbutton flex mt4 mb2 mb0-ns ${buttonCls}`
  const cta2Cls = `tbutton flex mt3 mb2 mb0-ns ${buttonCls}`
  let cta
  let cta2
  let bt
  let bt2
  let eventValue
  let eventValue2

  if (video && !props.link) {
    if (props.download) {
      bt = <a
        className={ctaCls}
        download='road-to-zero-video'
        href={video}
      >{props.linkLabel}</a>
    } else {
      bt = <a className={ctaCls} onClick={openMediaCb}>{props.linkLabel}</a>
    }
    eventValue = `${props.title}-lightbox-open-video-click`
    // works with normal and shorten urls
    if (video.indexOf('youtu') > -1) {
      videoEl = youtubePlayer(video)
    } else {
      videoEl = nativeVideoPlayer(video)
    }
  } else if (props.link) {
    eventValue = `${props.title}-cta-click`
    bt = <a className={ctaCls} href={props.link} target='_blank'>{props.linkLabel}</a>
  }

  if (bt) {
    cta = <Tracker value={eventValue}>{bt}</Tracker>
  }

  // Add support for second CTA button
  if (props.link2 && props.link2Label) {
    eventValue2 = `${props.title}-cta2-click`
    bt2 = <a className={cta2Cls} href={props.link2} target='_blank'>{props.link2Label}</a>
    cta2 = <Tracker value={eventValue2}>{bt2}</Tracker>
  }

  const image = <img src={props.imageUrl} className='image pa0' />

  let lightboxComponent
  if (hasLightbox) {
    if (video) {
      lightboxComponent = (
        <LinkLightbox video icon={icon} openMedia={props.openMedia}>
          {image}
          {videoEl}
        </LinkLightbox>
      )
    } else {
      lightboxComponent = (
        <LinkLightbox icon={icon} openMedia={props.openMedia}>{image}</LinkLightbox>
      )
    }

    lightboxComponent = (
      <Tracker value={`${props.title}-lightbox-open-click`}>
        {lightboxComponent}
      </Tracker>
    )
  }

  return (
    <div
      className={`panel-image image-${side} flex flex-column flex-row${direction}-l items-center justify-center-ns mv4-ns tc-m`}
    >
      { hasLightbox ? lightboxComponent : image }
      <div className='info pv5 pt5-m pb3-m pv0-l ph4 ph2-m ph0-ns'>
        {theme === 'white' && <span className='background ' />}
        <h1 className='info-title db mt2 mt0-ns lh-solid'>
          {
            props.htmlTitle
              ? <span dangerouslySetInnerHTML={{ __html: props.htmlTitle }} />
              : props.title
          }
        </h1>
        <div className={'info-details db fos f6 f5-ns lh-copy mt3' + (theme === 'white' ? ' fw4' : '')}>
          {details}
        </div>
        {cta}
        {cta2}
      </div>
    </div>
  )
}
