import {
  CAR_SHOWROOM_ID,
  CRASH_FORCES_ID,
  MEET_GRAHAM_ID,
  SPEED_PHYSICS_ID
} from './experiences'

export const HIDDEN_FROM_OUTREACH_LINKS = [
  CAR_SHOWROOM_ID,
  CRASH_FORCES_ID,
  MEET_GRAHAM_ID,
  SPEED_PHYSICS_ID
]

export const hiddenFromOutreach =
  id => HIDDEN_FROM_OUTREACH_LINKS.includes(id)
