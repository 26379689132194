import React, { Component } from 'react'
import ImageInfoPanel from '../panels/image-info'
import Placeholder from '../../../assets/placeholders/global-outlook.jpg'
import Section from '../../section'

export default class GlobalOutlookExperience extends Component {
  render () {
    const cta = this.getCta()
    return <Section id={this.props.id} theme='white'>
      <ImageInfoPanel
        title='GLOBAL OUTLOOK'
        link='/read-more/global-outlook'
        linkLabel={cta.label}
        imageUrl={Placeholder}
        noLightbox
      >
        <p>What does road safety look like around the world? We should learn from the safest countries in order to reduce road trauma in Victoria.</p>
      </ImageInfoPanel>
    </Section>
  }

  getCta () {
    return this.props.links && this.props.links.cta
      ? this.props.links.cta
      : { label: 'READ MORE', url: 'https://www.roadtozero.vic.gov.au/about-the-exhibit/experiences' }
  }
}
