import React, { Component } from 'react'
import Logo from '../../assets/logo-intro.svg'
import Tracker from '../gtm/event-tracker'
import './index.css'

export default class Footer extends Component {
  static propTypes = {}

  render () {
    const { support, legal } = this.props.links

    return (
      <div className='fos fw4 f7-m f5-l footer w-100 vh-100 h-auto flex items-center justify-between white tc pa3 pa4-l bt b--white bn-l mt5-l'>
        <Tracker value='footer-rtz-logo-click'>
          <a href='https://www.roadsafetyeducation.vic.gov.au/educational-resources/programs/roadsmart-interactive'><img className='footer-logo' src={Logo} /></a>
        </Tracker>
        <div className='lh-copy'>&copy; {new Date().getFullYear()} TAC VICTORIA. All rights reserved.</div>
        <div>
          <Tracker value='footer-support-click'>
            <a className='footer-link white no-underline' href={support.url}>SUPPORT</a>
          </Tracker>
          <Tracker value='footer-legal-click'>
            <a className='footer-link white ml3 ml-l no-underline' href={legal.url}>LEGAL</a>
          </Tracker>
        </div>
      </div>
    )
  }
}
