import { Component } from 'react'

export default class Home extends Component {
  componentWillMount () {
    window.location.replace('https://www.roadsafetyeducation.vic.gov.au/educational-resources/programs/roadsmart-interactive')
  }

  render () {
    return null
  }
}
