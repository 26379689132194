import React, { Component, Fragment } from 'react'
import Section from '../../section'
import Tracker from '../../gtm/event-tracker'
import Quiz from './quiz'
import './index.css'

export default class SaferBehavioursExperience extends Component {
  constructor (props) {
    super(props)
    this.state = {
      show: false,
      totalQuestions: countQuestions(props.content)
    }
    this.showMore = this.showMore.bind(this)
  }

  showMore () {
    this.setState({ show: !this.state.show })
  }

  render () {
    const cta = { label: 'READ MORE', url: 'https://www.roadtozero.vic.gov.au/about-the-exhibit/experiences#:~:text=Safer%20Behaviours%20Quiz' }
    const showGrad = this.state.show ? '' : 'show-grad overflow-hidden'
    const moreCopy = this.state.show ? `LESS` : `MORE`
    let showClass = 'h-100'
    let showMoreBt = null

    if (this.props.content) {
      showMoreBt = (
        <Tracker value='safer-behaviours-show-more-click'>
          <a onClick={this.showMore} className='tbutton fw4 mh5 mh0-ns'>
            {`SHOW ${moreCopy} ANSWERS`}
          </a>
        </Tracker>
      )

      if (this.state.totalQuestions > 1) {
        // only change the show class if there is content and also more than 1
        // question
        showClass = this.state.show ? 'h-100' : 'hide-quiz overflow-hidden'
      }
    }

    return <Section id={this.props.id} className='pv6-ns nt5-l mb5 mb3-l' theme='white' forceUpdate>
      <div className={`tl tc-ns pt5 ph4 ph2-m ph0-ns ${showClass}`}>
        <h1 className='heading1 lh-solid'>SAFER BEHAVIOURS</h1>
        <p className='fos fw4 black lh-copy mt2 mt4-m mt5-l ph5-m'>How much did you know about drink driving, speeding, driving while tired or distracted?</p>
        <Tracker value='safer-behaviours-cta-click'>
          <a href={cta.url} target='_blank' className='tbutton mt4'>{cta.label}</a>
        </Tracker>
        {this.props.content
          ? <Fragment>
            <p className='dn db-l fos fw4 cta black lh-copy mt5 pb4-ns'>
              SCROLL DOWN TO VIEW YOUR ANSWERS
            </p>

            <p className='tc fw7 f4 f3-m f2-l pt2 pt0-ns mt5 mt6-l black'>YOUR QUIZ ANSWERS:</p>
            <Quiz
              content={this.props.content}
              avatarUrl={this.props.user.avatarUrl}
            />
          </Fragment>
          : null }
      </div>
      {
        this.state.totalQuestions > 1 &&
        <Fragment>
          <div className={`tl tc-ns show-more-gradiend w-100 flex justify-center items-end absolute ${showGrad}`} />
          <div className='tl tc-ns show-more-container mt4 mb0 mb5-m mb6-l'>
            {showMoreBt}
          </div>
        </Fragment>
      }
    </Section>
  }
}

function countQuestions (content) {
  if (!content) return 0
  return Object.keys(content).length
}
