import React, { Fragment } from 'react'
import Section from '../../../section'
import ImageInfoPanel from '../../panels/image-info'
import SelectedSpeed from './selected-speed'
import Gallery from './gallery'
import {
  CREATIVE_SPACE_PHYSICS_RESULT_ID,
  CREATIVE_SPACE_PHYSICS_TREATMENTS_ID
} from '../../../../helpers/experiences'
import './index.css'
import { isRevoked } from '../../../../helpers/revoked'
import get from 'lodash/get'

export default function ({ id, data, openMedia }) {
  const hasBeenRevoked = isRevoked(get(data, 'graph.csvUrl'))

  const openMediaCb = (videoUrl) => {
    const videoEl = <video
      width='692'
      height='460'
      controls='true'
      src={videoUrl}
      className='image pa0'
      autoPlay='true'
    />
    openMedia(videoEl, { width: 692, height: 460 }, true)
  }

  const risksList = data.scenario.risks.map((r, i) => {
    if (!r) return null
    return <div key={`risk${i}`} className='w-80 w-third-ns pa3 mr0 mr2-ns mt3 mt0-ns tc '>
      <img className='mt0' src={r.icon} />
      <p className='copy mt2'>{r.copy}</p>
      <div>
        <a
          className='tbutton risk-button flex mt2 mt3-ns ml0-ns w4'
          onClick={() => openMediaCb(r.video)}
        >VIEW RISK</a>
      </div>
    </div>
  })

  return <Fragment>
    <Section id={id} theme='blue' useDecorativeLines>
      <div className='flex flex-column items-center pv5 ph4 tc'>
        <h1 className='heading1 lh-solid'>{data.podName} PHYSICS CONTENT</h1>
        <h2 className='mt5'>YOUR SCENARIO: {data.scenario.name}</h2>
        <p className='fos white lh-copy mt4 mw600 tc'>Road safety has come a long way in the last thirty years. Take a Road Trip down memory lane to explore the many life-saving changes and see what the future could look like.</p>
      </div>
    </Section>

    <Section id={CREATIVE_SPACE_PHYSICS_RESULT_ID} theme='white'>
      { !hasBeenRevoked && (
        <Fragment>
          <div className='flex flex-column items-center'>
            <p className='fos black lh-copy mt7 mt0-ns'>YOUR PHYSICS EXPERIMENT RESULTS</p>
          </div>
          <div className='mt4 mt5-ns'>
            <ImageInfoPanel
              title='THE RESULT RUNSHEET'
              link={data.graph.csvUrl}
              linkLabel='DOWNLOAD CSV DATA'
              imageUrl={data.graph.image}
              imageSide='left'
              theme='white'
              openMedia={openMedia}
            >
              <p>{data.graph.copy}</p>
            </ImageInfoPanel>
          </div>
          <div className='mt6 mt7-ns'>
            <ImageInfoPanel
              title='SCOREBOARD'
              imageUrl={data.scoreboard.image}
              imageSide='right'
              theme='white'
              openMedia={openMedia}
            >
              <p>{data.scoreboard.copy}</p>
            </ImageInfoPanel>
          </div>
        </Fragment>
      )}
      <div className='flex flex-column items-center mt6 mt7-ns'>
        <p className='fos black lh-copy'>YOUR SAFER ROAD ENVIRONMENT</p>
        <h1 className='heading1 lh-solid mt4 tc'>YOUR SAFE {data.scenario.name}</h1>
        <video
          src={data.scenario.video}
          preload='metadata'
          loop='false'
          controls='true'
          className='w-100 w-75-l mt5'
        />
        <h1 className='heading1 lh-solid mt6 mt7-ns'>{data.scenario.name} RISKS</h1>
        <div className='flex flex-column flex-row-ns justify-around items-center mt5 w-100 w-75-ns'>
          {risksList}
        </div>
      </div>
      <SelectedSpeed data={data.speed} />
    </Section>
    <Section id={CREATIVE_SPACE_PHYSICS_TREATMENTS_ID} theme='blue'>
      <div className='tl tc-ns flex flex-column items-center-ns'>
        <h1 className='heading1 lh-solid pv5 ph4'>YOUR TREATMENTS</h1>
        <div className='treatments'>
          <Gallery items={data.treatments} openMedia={openMedia} />
        </div>
        <p className='fos tc ph4 ph0-ns mt4'>SELECT TO VIEW THE TREATMENT ANIMATION</p>
      </div>
    </Section>
  </Fragment>
}
