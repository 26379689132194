import React from 'react'
import TickSVG from '../../svg/tick'

export default function GalleryItem (props) {
  const { title, body, imageUrl, score } = props.content
  const ticksList = []
  let color
  for (let i = 0; i < 3; i++) {
    color = (score[i] === 5) ? '#fff' : '#1348A3'
    ticksList.push(<TickSVG key={`gi-key-${i}`} color={color} />)
  }

  return (
    <div className='gallery-item flex flex-column flex-row-l w-100 justify-center items-center items-start-l'>
      <div className='gallery-item-image'>
        <img src={imageUrl} />
      </div>
      <div className='gallery-item-info tc tl-l ph4'>
        <p className='f4 f2-m f1-l fw4 title pt4 pt3-l lh-solid mt3 mt0-l'>{title}</p>
        <div className='answers flex pt2 pb1 pt1-l pb0-l justify-center justify-start-l'>
          {ticksList}
        </div>
        <p className='fos f6 f5-ns lh-copy pt1'>{body}</p>
      </div>
    </div>
  )
}
