import React, { Component } from 'react'
import ImageInfoPanel from '../panels/image-info'
import vrImage from '../../../assets/images/vr/vr_portal_asset.jpg'
import Section from '../../section'
import video360Icon from '../../../assets/icons/video-360-icon.svg'

export default class VRExperience extends Component {
  render () {
    const videoUrl = this.props.links.youtubevideo.url
    return <Section id={this.props.id} theme='white'>
      <ImageInfoPanel
        title='ROAD TRIP TO ZERO'
        htmlTitle='<div class="di-m">ROAD TRIP </div>TO ZERO'
        link=''
        linkLabel='TAKE THE ROAD TRIP'
        link2='/read-more/road-trip-to-zero'
        link2Label='READ MORE'
        imageUrl={vrImage}
        videoUrl={`${videoUrl}`}
        imageSide='right'
        icon={video360Icon}
        openMedia={this.props.openMedia}
        noLightbox
      >
        <p>Road safety has come a long way in the last thirty years. Take a Road Trip down memory lane to explore the many life-saving changes and see what the future could look like.</p>
      </ImageInfoPanel>
    </Section>
  }
}
