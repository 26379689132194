import React from 'react'

export default function Plegde (props) {
  const list = props.list.map((item, i) => {
    let c
    if (typeof item === 'object') {
      c = <a className='f6 f5-ns fw3 fw4-ns dim underline nl1-ns dib' href={item.url} target='_blank'>{item.text}</a>
    } else {
      c = item
    }

    return <li
      key={`pl-item-${i}`}
      className='mv2 mv3-ns ml0 ml3-ns lh-copy fos'
    >{c}</li>
  })
  return (
    <div className='pledge flex flex-column flex-row-ns ph0 ph3-ns mt4 mt0-ns'>
      <div
        className='pledge-title fw3 fw4-ns f5 f4-m f3-l pr0 pr4-ns pv4-ns tl tr-ns'
        dangerouslySetInnerHTML={{ __html: props.title }}
      />
      <ul className='pledge-list ml0 ml3-ns pl3 tl'>{list}</ul>
    </div>
  )
}
