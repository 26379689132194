import React, { Component } from 'react'
import './style.css'

export default class Section extends Component {
  shouldComponentUpdate (b, a) {
    return this.props.forceUpdate || false
  }

  render () {
    const c = this.props.theme ? this.props.theme : 'white'
    const { useDecorativeLines, mobile, id } = this.props
    let decorativeLinesClassName = 'decorative-lines'
    if (useDecorativeLines === 'right') decorativeLinesClassName += ' right'
    if (mobile && id === 'intro') decorativeLinesClassName += ' mobile-intro'

    const bgLines = useDecorativeLines
      ? <svg className={decorativeLinesClassName} viewBox='0 0 477 449'>
        <path d='M311.94 437.8c-9.6 1.56-19.76 2.36-30.5 2.36s-21.45-.12-31.91-.34a4.12 4.12 0 0 0-4.2 4.03 4.113 4.113 0 0 0 4.03 4.2c10.51.22 21.2.34 32.08.34s21.75-.83 31.82-2.47a4.11 4.11 0 0 0 3.4-4.72 4.11 4.11 0 0 0-4.72-3.4Zm79.76-40.44c-14.34 14.43-31.02 25.46-50.36 32.71-2.13.8-3.21 3.17-2.41 5.3.8 2.13 3.17 3.21 5.3 2.41 20.5-7.68 38.18-19.38 53.32-34.62a4.12 4.12 0 0 0-.02-5.82 4.12 4.12 0 0 0-5.82.02Zm72.69-171.88a4.118 4.118 0 0 0-4.66 3.5c-3.08 21.64-7.1 41.95-12.16 60.78-.59 2.2.71 4.45 2.91 5.04 2.2.59 4.46-.71 5.05-2.91 5.16-19.2 9.24-39.85 12.36-61.76a4.118 4.118 0 0 0-3.5-4.66Zm7.21-26.36c1.97-19.95 3.25-40.8 3.95-62.43a4.127 4.127 0 0 0-3.98-4.25 4.127 4.127 0 0 0-4.25 3.98c-.69 21.47-1.96 42.14-3.91 61.89a4.117 4.117 0 0 0 3.69 4.5c2.26.22 4.28-1.43 4.5-3.69Zm-33.24 120.06c-7.48 20.62-16.53 38.93-27.31 54.66a4.104 4.104 0 0 0 1.07 5.72c1.88 1.29 4.44.81 5.72-1.07 11.25-16.41 20.59-35.36 28.26-56.5.78-2.14-.33-4.5-2.47-5.27-2.14-.78-4.5.33-5.27 2.47Zm32.55-280.33a4.115 4.115 0 0 0-3.97 4.26c.74 21.27 1.08 42.01.95 62.15-.01 2.27 1.82 4.13 4.09 4.14 2.27.01 4.13-1.82 4.14-4.09.12-20.26-.21-41.11-.96-62.48a4.121 4.121 0 0 0-4.26-3.97Zm-5.32-26.86a4.115 4.115 0 0 0 4.32 3.9 4.115 4.115 0 0 0 3.9-4.32c-.2-3.84-.42-7.7-.64-11.56h-8.25c.23 4.01.46 8.01.66 11.99ZM153.78 435.81a4.116 4.116 0 1 0-.44 8.22c20.42 1.1 41.75 2.24 63.97 3.07 2.27.09 4.18-1.69 4.27-3.96.09-2.27-1.69-4.18-3.96-4.27-22.15-.83-43.42-1.96-63.83-3.06Zm-127.68 5c2.27-.11 4.03-2.03 3.92-4.3-.11-2.27-2.03-4.03-4.3-3.92-8.81.41-17.38 1.04-25.72 1.95v8.3c8.42-.94 17.1-1.61 26.1-2.02ZM57.77 432a4.12 4.12 0 0 0-.04 8.24c20.04.11 41.28.97 63.74 2.11 2.27.11 4.21-1.63 4.32-3.9.11-2.27-1.63-4.21-3.9-4.32-22.5-1.14-43.89-2.01-64.12-2.12Z' style={{ fill: '#fff' }} />
      </svg>
      : null

    let content = <h1>CONTENT</h1>
    if (this.props.children) {
      content = this.props.children
    }

    const cls = this.props.className ? this.props.className : null

    return (
      <div
        className={`flex section justify-center items-center theme-${c} ph0 ph4-l`}
        id={this.props.id}>
        {bgLines}
        <div className={`content pv0 pt4-m pb5-m pv6-l ${cls}`}>
          {content}
        </div>
      </div>
    )
  }
}
